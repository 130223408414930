// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-e-commerce-js": () => import("./../../../src/pages/oferta/e-commerce.js" /* webpackChunkName: "component---src-pages-oferta-e-commerce-js" */),
  "component---src-pages-oferta-marketing-internetowy-js": () => import("./../../../src/pages/oferta/marketing-internetowy.js" /* webpackChunkName: "component---src-pages-oferta-marketing-internetowy-js" */),
  "component---src-pages-oferta-strony-www-index-js": () => import("./../../../src/pages/oferta/strony-www/index.js" /* webpackChunkName: "component---src-pages-oferta-strony-www-index-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-portfolio-eubezpieczeniaonline-js": () => import("./../../../src/pages/portfolio/eubezpieczeniaonline.js" /* webpackChunkName: "component---src-pages-portfolio-eubezpieczeniaonline-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-nieladakawa-js": () => import("./../../../src/pages/portfolio/nieladakawa.js" /* webpackChunkName: "component---src-pages-portfolio-nieladakawa-js" */),
  "component---src-pages-portfolio-perfumcity-js": () => import("./../../../src/pages/portfolio/perfumcity.js" /* webpackChunkName: "component---src-pages-portfolio-perfumcity-js" */),
  "component---src-pages-portfolio-seepoint-js": () => import("./../../../src/pages/portfolio/seepoint.js" /* webpackChunkName: "component---src-pages-portfolio-seepoint-js" */),
  "component---src-pages-portfolio-wdresie-js": () => import("./../../../src/pages/portfolio/wdresie.js" /* webpackChunkName: "component---src-pages-portfolio-wdresie-js" */)
}

